import { VNodeData } from 'vue';

export function mergeClasses(
  data: VNodeData | undefined,
  classes: Record<string, boolean>,
): VNodeData {
  const d = data || {};
  if (d.class) {
    for (const [k, v] of Object.entries(classes)) {
      d.class[k] = v;
    }
  } else {
    d.class = classes;
  }
  return d;
}
