
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DeepReadonly } from 'ts-essentials';
import { isSelectOptions, SelectOption } from '@/components/form/form.types';
import SwapChip from '@/components/SwapChip.vue';

const required = true;
@Component({
  components: { SwapChip },
})
export default class FilterSelector extends Vue {
  @Prop({ type: Array, required }) readonly value!: DeepReadonly<SelectOption['value'][]>;
  @Prop({ type: Array, required }) readonly options!: SelectOption[] | string[];
  @Prop(Boolean) readonly inline!: boolean;
  @Prop(Boolean) readonly singleSelect!: boolean;

  isChecked(option: { value: string }) {
    return this.value.includes(option.value);
  }

  chipUpdate(key: string, isOn: boolean) {
    let val = this.value.filter(k => k !== key);
    if (isOn) {
      if (this.singleSelect) {
        val = [key];
      } else {
        val.push(key);
      }
    }
    this.$emit('input', val);
    if (!isOn) {
      this.$emit('remove', key);
    }
  }

  get formattedOptions(): SelectOption[] {
    if (!isSelectOptions(this.options)) {
      return this.options.map(option => {
        return {
          key: option,
          value: option,
          label: option,
          disabled: false,
        };
      });
    }

    return this.options.map(option => {
      const out = { ...option };
      if (!out.key) {
        out.key = out.value;
      }
      if (!out.label) {
        out.label = out.value;
      }
      return out;
    });
  }
}
