
import { Component, Prop, Vue } from 'vue-property-decorator';
import { randId } from '@/utils/components';
const required = true;
@Component({
  inheritAttrs: false,
})
export default class GSwitch extends Vue {
  @Prop({ type: Boolean }) readonly value!: boolean;
  @Prop({ type: String, default: 'switch' }) readonly name!: string;
  @Prop({ type: String, required }) readonly label!: string;

  get autoLabel(): string {
    if (this.label === '') {
      return this.value ? 'Enabled' : 'Disabled';
    }
    return this.label;
  }

  get randName(): string {
    return `${this.name}_${randId()}`;
  }

  get eventHandlers() {
    return {
      change: this.input,
      blur: (e: Event) => this.$emit('blur', e),
    };
  }
  input(e: Event) {
    this.$emit('input', (e.target as HTMLInputElement)?.checked ?? false);
  }
}
