
import { Component, Vue } from 'vue-property-decorator';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import GCheckbox from '@/components/gsk-components/GskCheckbox.vue';
import FilterSelector from '@/components/FilterSelector.vue';
import CatalogFilters from '@/components/catalog/CatalogFilters.vue';
import GridCell from '@/components/grid/GridCell';
import Grid from '@/components/grid/Grid';
import MarkdownEditor from '@/components/MarkdownEditor.vue';
import GTextfield from '@/components/gsk-components/GskTextfield.vue';
import GButton from '@/components/gsk-components/GskButton.vue';
import GCheckboxGroup from '@/components/gsk-components/GskCheckboxGroup.vue';
import KeyValueInput from '@/components/form/KeyValueInput/KeyValueInput.vue';
import { FormFieldValidaiton, KeyValueField, SwitchField } from '@/components/form/form.types';
import ValidatedForm from '@/components/form/ValidatedForm.vue';
import GSwitch from '@/components/gsk-components/GskSwitch.vue';
import PeoplePickerTable from '@/components/PeoplePickerTable.vue';
import { BaseUser } from '@/types/users.types';

@Component({
  components: {
    GPeoplePicker,
    GCheckbox,
    GTextfield,
    GButton,
    GCheckboxGroup,
    FilterSelector,
    CatalogFilters,
    GridCell,
    Grid,
    MarkdownEditor,
    KeyValueInput,
    ValidatedForm,
    GSwitch,
    PeoplePickerTable,
  },
})
export default class TestFormView extends Vue {
  swi = true;

  ppl: BaseUser[] = [
    {
      email: 'stephan.x.tabor@gsk.com',
      mudId: 'st536122',
    },
    {
      email: 'jeff.x.tabor@gsk.com',
      mudId: 'jt536122',
    },
  ];

  form: [SwitchField, KeyValueField] = [
    {
      type: 'switch',
      value: false,
      label: 'Give me query strings!!1!',
      key: 'yes-query',
    },
    {
      type: 'key-value',
      keyLabel: 'Parameter',
      valueLabel: 'Value',
      label: 'Query String',
      helpText: 'Kong will surely do something with this',
      value: [],
      key: 'queryString',
      hideIf: {
        fieldKey: 'yes-query',
        fieldValue: false,
      },
    },
  ];

  keyValidation: FormFieldValidaiton = {
    rules: 'required|regex:^[/a-zA-Z0-9_-]+[a-zA-Z0-9_-]$',
    customMessages: {
      regex: 'Alphanumeric, forward slashes, underscores, dashes, no trailing slash',
    },
  };
  validation: FormFieldValidaiton = {
    rules: 'arrayMax:5',
    customMessages: {
      regex: 'Alphanumeric, forward slashes, underscores, dashes, no trailing slash',
    },
  };
}
