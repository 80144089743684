
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseUser } from '@/types/users.types';
import GPeoplePicker from '@/components/gsk-components/GskPeoplePicker.vue';
import DataTable from '@/components/table/DataTable.vue';
import { TableDefinition } from '@/components/table/table.types';
import GButton from '@/components/gsk-components/GskButton.vue';
import uniqBy from 'lodash/uniqBy';
import { randInt } from '@/utils/components';

interface TableData {
  label: string;
  action: BaseUser;
  mudId: string;
}

// id is primarily to support RPA Dashboard functionality,
// can be ignored most of the time

@Component({
  components: {
    GButton,
    GPeoplePicker,
    DataTable,
  },
})
export default class PeoplePickerTable extends Vue {
  @Prop(Array) readonly value!: BaseUser[];
  @Prop({ type: Boolean, default: true }) readonly valid!: boolean;
  @Prop({ type: String, default: 'Select people' }) readonly label!: string;
  @Prop({ type: String, default: 'mudId' }) readonly tableFieldKey!: keyof BaseUser;
  @Prop({ type: String, default: 'User' }) readonly tableLabel!: string;

  tempPeople: BaseUser[] = [];

  get people() {
    return this.value;
  }

  set people(v: BaseUser[]) {
    const p = v.map(user => {
      if (!user.userId) {
        user.userId = randInt() * -1;
      }
      return user;
    });
    this.$emit('input', p);
  }

  addPeople(): void {
    this.people = uniqBy([...this.people, ...this.tempPeople], 'mudId');
    this.tempPeople = [];
  }

  removeUser(user: BaseUser): void {
    this.people = this.people.filter(u => u.mudId !== user.mudId);
  }

  get tableDef(): TableDefinition<TableData> {
    return {
      data: this.people.map(p => ({
        label: p[this.tableFieldKey] + '',
        action: p,
        mudId: p.mudId,
      })),
      columns: [
        {
          key: 'label',
          label: this.tableLabel,
        },
        {
          key: 'action',
          label: '',
        },
      ],
      rowKey: 'mudId',
    };
  }

  get eventHandlers() {
    return {
      change: (e: Event) => this.$emit('change', e),
      blur: (e: Event) => this.$emit('blur', e),
    };
  }
}
