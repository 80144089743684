
import { Component, Prop, Vue } from 'vue-property-decorator';
import GCheckboxGroup from '@/components/gsk-components/GskCheckboxGroup.vue';
import FilterSelector from '@/components/FilterSelector.vue';
import { SelectOption } from '@/components/form/form.types';
import { getQuery, slugify, updateQuery } from '@/utils/routing';
import {
  ListingsModule as ListingCatalogModule,
  ListingsModule,
} from '@/store/modules/listings.module';
import NavigationList from '@/components/NavigationList.vue';
import { UINavigationItem } from '@/types';
import { RouteNames } from '@/constants';

@Component({
  components: {
    GCheckboxGroup,
    FilterSelector,
    NavigationList,
  },
})
export default class CatalogFilters extends Vue {
  @Prop(Boolean) noCollapse!: boolean;

  collapsed = {
    category: false,
    bu: false,
    keyword: false,
  };

  get showFilters(): boolean {
    return !!this.$route.params.type;
  }

  toggle(key: keyof CatalogFilters['collapsed']) {
    this.collapsed[key] = !this.collapsed[key];
  }

  chevron(key: keyof CatalogFilters['collapsed']) {
    return this.collapsed[key] ? 'chevron_down' : 'chevron_up';
  }

  public get tabs(): UINavigationItem[] {
    const catalogs = ListingCatalogModule.listingTypes;
    return (catalogs || [])
      .filter(type => type.listingTypeId !== 0)
      .reduce((array: UINavigationItem[], type) => {
        array.push({
          text: type.listingTypeName.trim(),
          key: slugify(type.listingTypeName),
          props: {
            graphic: `/img/listing-types/small/${type.listingTypeId}.svg`,
          },
          route: {
            name: RouteNames.ListingType,
            params: {
              type: slugify(type.listingTypeName),
            },
          },
        });
        return array;
      }, []);
  }

  get listingType() {
    const { type } = this.$route.params;
    return ListingsModule.listingTypes.find(t => slugify(t.listingTypeName) === type);
  }

  get businessUnits() {
    return getQuery(this, 'businessUnitIds', { toArray: true, toNumber: false });
  }
  set businessUnits(v) {
    updateQuery(this, 'businessUnitIds', v).catch(() => {});
  }
  get buOptions(): SelectOption[] {
    return ListingsModule.businessUnits.map(bu => ({
      value: bu.businessUnitId.toString(),
      label: bu.businessUnitName,
    }));
  }

  get keywords() {
    return getQuery(this, 'keyword', { toArray: true, toNumber: false });
  }
  set keywords(v) {
    updateQuery(this, 'keyword', v).catch(() => {});
  }
  get keywordOptions(): SelectOption[] {
    return ListingsModule.keywords.map(k => ({
      value: k.keywordId.toString(),
      label: k.keywordValue,
    }));
  }

  get categories() {
    return getQuery(this, 'categoryId', { toArray: true, toNumber: false });
  }
  set categories(v) {
    updateQuery(this, 'categoryId', v).catch(() => {});
  }
  get categoryOptions(): SelectOption[] {
    return (
      this.listingType?.category
        .filter(cat => cat.categorycount > 0)
        .map(cat => ({
          value: cat.categoryId.toString(),
          label: cat.categoryName,
        })) ?? []
    );
  }
}
