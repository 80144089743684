
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

@Component
export default class SwapChip extends Vue {
  @PropSync('on', { type: Boolean }) isOn!: boolean;
  @Prop({ type: String, required: true }) label!: string;

  toggle() {
    this.isOn = !this.isOn;
  }
}
