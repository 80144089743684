import Vue, { CreateElement, RenderContext, VNode } from 'vue';
import { mergeClasses } from '@/components/grid/utils';

interface Props {
  tag: string;
  span: number | string;
  lgSpan: number | string;
  mdSpan: number | string;
  smSpan: number | string;
  align: 'top' | 'middle' | 'bottom' | '';
  order: number | string;

  // utility
  hideSm: boolean;
  hideSmUp: boolean;
  hideSmDown: boolean;

  hideMd: boolean;
  hideMdUp: boolean;
  hideMdDown: boolean;

  hideLg: boolean;
  hideLgUp: boolean;
  hideLgDown: boolean;
}

export default Vue.extend<Props>({
  name: 'GridCell',
  functional: true,
  props: {
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
    span: {
      type: [Number, String],
      default: 0,
    },
    lgSpan: {
      type: [Number, String],
      default: 0,
    },
    mdSpan: {
      type: [Number, String],
      default: 0,
    },
    smSpan: {
      type: [Number, String],
      default: 0,
    },
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    align: {
      default: '',
    },
    order: {
      type: [Number, String],
      default: 0,
    },
    hideSm: Boolean,
    hideSmUp: Boolean,
    hideSmDown: Boolean,

    hideMd: Boolean,
    hideMdUp: Boolean,
    hideMdDown: Boolean,

    hideLg: Boolean,
    hideLgUp: Boolean,
    hideLgDown: Boolean,
  },
  render(h: CreateElement, ctx: RenderContext<Props>): VNode | VNode[] {
    const { props } = ctx;
    const classes = {
      'grid-cell': true,
      [getSpan(props.span)]: !!props.span,
      [getSpan(props.lgSpan, 'lg')]: !!props.lgSpan,
      [getSpan(props.mdSpan, 'md')]: !!props.mdSpan,
      [getSpan(props.smSpan, 'sm')]: !!props.smSpan,
      [`align-${props.align}`]: !!props.align,
      [`order-${props.order}`]: !!props.order,
      'gsk-hide-sm': props.hideSm,
      'gsk-hide-sm-up': props.hideSmUp,
      'gsk-hide-sm-down': props.hideSmDown,
      'gsk-hide-md': props.hideMd,
      'gsk-hide-md-up': props.hideMdUp,
      'gsk-hide-md-down': props.hideMdDown,
      'gsk-hide-lg': props.hideLg,
      'gsk-hide-lg-up': props.hideLgUp,
      'gsk-hide-lg-down': props.hideLgDown,
    };
    if (props.tag) {
      return h(props.tag, mergeClasses(ctx.data, classes), ctx.children);
    }
    return ctx.children.map(child => {
      if (child)
        if (child.data?.class) {
          child.data.class = {
            ...child.data.class,
            ...classes,
          };
        } else if (child.data) {
          child.data.class = classes;
        } else {
          child.data = { class: classes };
        }
      return child;
    });
  },
});

function getSpan(span?: string | number, size?: 'lg' | 'sm' | 'md'): string {
  if (span) {
    return size ? `span-${span}-${size}` : `span-${span}`;
  }

  return '';
}
