import Vue, { CreateElement, RenderContext, VNode } from 'vue';
import { mergeClasses } from '@/components/grid/utils';

interface Props {
  outerTag: string;
  innerTag: string;
  dense: boolean;
  expanded: boolean;
  fluid: boolean;
  noPad: boolean;
  slim: boolean;
  align?: 'left' | 'right' | '';
}

export default Vue.extend<Props>({
  name: 'Grid',
  functional: true,
  props: {
    outerTag: {
      type: String,
      default: 'section',
    },
    innerTag: {
      type: String,
      default: 'div',
    },
    // eslint-disable-next-line vue/require-prop-types
    align: {
      default: '',
    },
    expanded: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
    fluid: {
      type: Boolean,
    },
    noPad: {
      type: Boolean,
    },
    slim: {
      type: Boolean,
    },
  },
  render(h: CreateElement, ctx: RenderContext<Props>): VNode | VNode[] {
    const { props } = ctx;
    const classes = {
      'gsk-grid': true,
      'grid-fluid': props.fluid,
      'grid-slim': props.slim,
      'grid-no-pad': props.noPad,
      expanded: props.expanded,
      dense: props.dense,
      [`align-${props.align}`]: !!props.align,
    };
    if (ctx.data.class) {
      for (const [k, v] of Object.entries(classes)) {
        ctx.data.class[k] = v;
      }
    } else {
      ctx.data.class = classes;
    }
    return h(props.outerTag, mergeClasses(ctx.data, classes), [
      h(props.innerTag, { class: { 'grid-inner': true } }, ctx.children),
    ]);
  },
});
